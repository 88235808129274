import React, {
  ChangeEvent, useCallback,
} from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import DefaultImg from '../../assets/default-img.svg';

import {
  Container,
  EditButton,
} from './styles';

interface AvatarProps {
  avatar_url: string;
  alt_name: string;
  handleChange(e: ChangeEvent<HTMLInputElement>): void;
  edit?: boolean;
  width?: number;
  height?: number;
}

const Avatar: React.FC<AvatarProps> = ({
  avatar_url, alt_name, handleChange, edit = false, width = 40, height = 40,
}) => {
  const addDefaultSrc = useCallback((ev) => {
    ev.target.src = DefaultImg;
  }, []);
  return (
    <Container width={width} height={height} edit={edit}>
      <img
        src={avatar_url}
        onErrorCapture={addDefaultSrc}
        alt={alt_name}
      />
      {edit && (
      <EditButton>
        <label htmlFor="avatar">
          <input type="file" id="avatar" accept="image/png image/jpg" onChange={handleChange} />
          <AiOutlineEdit size={22} />
        </label>
      </EditButton>
      )}
    </Container>
  );
};

export default Avatar;
