import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Container,
} from './styles';

interface IItemProps {
  title: string;
  path: string;
  icon: IconType;
  onClick?: Function | undefined
}

const MenuItem: React.FC<IItemProps> = ({
  title, path, icon: Icon, onClick = undefined,
}) => {
  const location = useLocation();
  const history = useHistory();

  const confirmNavigation = useCallback(() => {
    const lastPathName = location.pathname.split('/')[1];
    onClick ? onClick() : history.push(path);

    if (lastPathName === 'avaliar') {
      window.location.reload();
    }
  }, [onClick, history, location, path]);

  const isSelected = useMemo(
    () => `/${location.pathname.split('/')[1]}` === path,
    [location, path],
  );

  return (
    <Container isSelected={isSelected} onClick={confirmNavigation}>
      <div>
        {Icon && <Icon size={24} />}
        <h1>{title}</h1>
      </div>
    </Container>
  );
};
export default MenuItem;
