// contexto é a forma de criar váriaveis globais entre components do react
// importação do react e hooks
import jwtDecode from 'jwt-decode';
import React, {
  createContext, useCallback, useState, useContext,
} from 'react';
import api from '../services/api';

// importação da coneção da api
// import api from '../services/api';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  role: string;
  company_id: string;
}

// define o tipo dos estados de autenticação
interface AuthState {
  token: string;
  user: IUser;
  refresh_token: string;
}

// define o tipo das credenciais do signin
interface SingInCredentials {
  email: string;
  password: string;
}

// define o tipo dos dados do contexto de autenticação
interface AuthContextData {
  user: IUser;
  signIn(crenditials: SingInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

interface DecodedProps {
  [key: string] : string | Number;
  exp: number;
}
// cria o contexto de autenticação
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// cria o provedor do contexto
const AuthProvider: React.FC = ({ children }) => {
  // define o estado inicial dos dados
  const [data, setData] = useState<AuthState>(() => {
    // busca no local storage os dados
    const token = localStorage.getItem('@Goniotech:token');
    const user = localStorage.getItem('@Goniotech:user');
    const refresh_token = localStorage.getItem('@Goniotech:refresh_token');

    // se houver dados no storage, retorna um objeto com esses dados
    if (token && user && refresh_token) {
      const decoded: DecodedProps = jwtDecode(token);

      const expirationTime = (decoded.exp * 1000);

      if (Date.now() >= expirationTime) {
        // retorna um objeto vazio
        return {} as AuthState;
      }

      api.defaults.headers.common.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), refresh_token };
    }

    // retorna um objeto vazio
    return {} as AuthState;
  });

  // função de signin usando callback
  const signIn = useCallback(async ({ email, password }) => {
    // faz a coneção da rota passando os dados
    const response = await api.post('/sessions', { email, password });

    // guar os dados da resposta
    const { token, refresh_token } = response.data;

    // salva os dados no local storage
    localStorage.setItem('@Goniotech:token', token);
    localStorage.setItem('@Goniotech:refresh_token', refresh_token);

    api.defaults.headers.common.authorization = `Bearer ${token}`;

    const responseProfile = await api.get('/users/profile');

    const profile = responseProfile.data;

    localStorage.setItem('@Goniotech:user', JSON.stringify(profile));

    // atualiza o estado dos dados
    setData({ token, user: profile, refresh_token });
  }, []);

  // função de signout usando callback
  const signOut = useCallback(() => {
    // limpa os dados do local storage
    localStorage.removeItem('@Goniotech:token');
    localStorage.removeItem('@Goniotech:user');
    localStorage.removeItem('@Goniotech:refresh_token');

    // atuliaza o estado com um objeto vazio
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback((user: IUser) => {
    // salva os dados no local storage
    localStorage.setItem('@Goniotech:user', JSON.stringify(user));

    setData({
      token: data.token,
      user,
      refresh_token: data.refresh_token,
    });
  },
  [setData, data.token, data.refresh_token]);

  // retorna o provider envolvendo os outros components
  return (
    <AuthContext.Provider value={{
      user: data.user, signIn, signOut, updateUser,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// função para retornar o contexto de autenticação da aplicação
function useAuth(): AuthContextData {
  // atribui o contexto ao hook
  const context = useContext(AuthContext);

  // se não existir retorna um erro
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  // retorna o contexto
  return context;
}

// exporta o provedor e o hook de autenticação
export { AuthProvider, useAuth };
