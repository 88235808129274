import styled, { css } from 'styled-components';
import { theme } from '../../global/styles/styles';

interface AvatarProps {
  edit: boolean;
  width: number;
  height: number;
}

export const EditButton = styled.div`
  position: absolute;
  background: rgba(0 , 0, 0, .5);
  visibility: hidden;
  border-radius: 50%;

  label {
    position: absolute;
    width: 100%;
    height: 100%;

    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    input {
      display: none;
    }

    svg {
      color: ${theme.colors.secondary00};
    }
  }
`;

export const Container = styled.div<AvatarProps>`
  background-color: ${theme.colors.primary100};
  border-radius: 50%;
  position: relative;
  display: flex;
  margin-bottom: 16px;

  ${(props) => css`
      width: ${props.width}px;
      height: ${props.height}px;
  `}

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }

  ${EditButton} {
    ${(props) => props.edit && css`
      width: ${props.width}px;
      height: ${props.height}px;
    `}
  }

  :hover {
    ${EditButton} {
      ${(props) => props.edit && css`
        visibility: visible;
      `}
    }

    cursor: pointer;
  }
`;
