import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  min-height: 60px;

  margin: 0 18px;

  text-align: left;
  align-items: center;

  border-bottom: 1px solid #E7E5E5;
  grid-template-columns: 1fr 1fr 1fr;

  h1 {
    flex: 1;
    display: flex;
    width: 100%;

    font-size: 12px;
    font-weight: 500;

    padding-left: 15px;
    color: #7A7A7A;


    align-items: center;
  }



  :hover {
    background: #F7F7F7;

  }

  cursor: pointer;
`;

export const ButtonArea = styled.div`
  display: flex;

  button {
    display: flex;
    text-align: center;

    color: #507DBC;

    margin-left: 18px;

    background: transparent;
    border: 0;

  }
`;
