import styled, { css } from 'styled-components';
import { theme } from '../../../global/styles/styles';
import Tooltip from '../../Tooltip';

interface ContainerPros {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disable: boolean;
  selected: boolean;
  grid_column?: string;
  label_color?: string;
}

export const Container = styled.div<ContainerPros>`
  width: 100%;
  flex-direction: column;

  min-height: 64px;
  max-height: 64px;


  text-align: left;

  > div {
    border-radius: 4px;
    border: 1px solid ${theme.colors.secondary60};
  }


  h1 {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: normal;
    color: ${theme.colors.secondary80};

    ${(props) => props.label_color && css`
      color: ${props.label_color};
      font-weight: bold;
    `}
  }

  input {
    width: 100%;
    height: 34px;
    flex: 1;
    background: transparent;
    border: 0;
    color: ${theme.colors.secondary40};
    padding: 0 18px;
    font-size: 14px;

    span {
      padding: 0 18px;
    }

    &::placeholder {
      color: ${theme.colors.secondary60};
    }
  }

  svg {
    margin-right: 16px;
  }

  ${(props) => props.disable && css`
    border-color: ${theme.colors.secondary40};

    h1 {
      color: ${theme.colors.secondary40};
    }

    input {
      color: ${theme.colors.secondary40};

      &::placeholder {
        color: ${theme.colors.secondary40};
      }
    }
  `}

  ${(props) => props.isErrored && css`
    border-color: ${theme.colors.error};
  `}

  ${(props) => props.isFocused && css`
    border-color: ${theme.colors.primary50};
  `}

  ${(props) => props.grid_column && css`
    grid-column: ${props.grid_column};
  `}

  ${(props) => props.selected && css`
    > div {
      border: 1px solid ${theme.colors.primary50};
    }

    h1 {
      color: ${theme.colors.primary50};
    }
  `}
`;

export const Error = styled(Tooltip)`
  align-self: center;
  height: 20px;
  margin: 0 8px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
