/* eslint-disable no-restricted-syntax */
import React, {
  useRef, useEffect, useCallback, useState, useMemo, MutableRefObject,
} from 'react';
import Webcam from 'react-webcam';
import {
  Holistic,
  POSE_LANDMARKS,
  POSE_LANDMARKS_LEFT,
  POSE_LANDMARKS_RIGHT,
  POSE_CONNECTIONS,
  HAND_CONNECTIONS,

} from '@mediapipe/holistic';

import {
  drawConnectors,
  drawLandmarks,
  lerp,
} from '@mediapipe/drawing_utils';
import { Camera } from '@mediapipe/camera_utils';

import {
  Container,
} from './styles';

import { getAngle } from '../../utils/angleUtilities';
import { BodyPartProps, MovementTypeProps } from '../../pages/Evaluate/data/options';

interface MPHolisticProps {
  member_selected: BodyPartProps;
  movement_selected: MovementTypeProps;
  side: string,
  onChangeAngle: Function;
  onStarted: Function;
}

interface ResultsProps {
  rightHandLandmarks: any,
  leftHandLandmarks: any,
  poseLandmarks: any,
}

const videoConstraints = {
  facingMode: 'environment',
};

export const MPHolistic: React.FC<MPHolisticProps> = (
  {
    member_selected,
    movement_selected,
    side,
    onChangeAngle,
    onStarted,
  },
) => {
  const webcamRef = useRef(null);
  const [showCam, setShowCam] = useState(true);
  const canvasRef = useRef({} as any);

  const [capturedResults, setCapturedResults] = useState({} as ResultsProps);

  const removeElements = useCallback((landmarks, elements) => {
    for (const element of elements) {
      delete landmarks[element];
    }
  }, []);

  const removeLandmarks = useCallback((results) => {
    if (results.poseLandmarks) {
      removeElements(
        results.poseLandmarks,
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22],
      );
    }

    if (results.rightHandLandmarks) {
      removeElements(
        results.rightHandLandmarks,
        [1, 2, 3, 4, 6, 7, 8, 10, 11, 14, 15, 16, 18, 19, 20],
      );
    }

    if (results.leftHandLandmarks) {
      removeElements(
        results.leftHandLandmarks,
        [1, 2, 3, 4, 6, 7, 8, 10, 11, 14, 15, 16, 18, 19, 20],
      );
    }
  }, [removeElements]);

  const connect = useCallback((ctx, connectors) => {
    const { canvas } = ctx;
    for (const connector of connectors) {
      const from = connector[0];
      const to = connector[1];
      if (from && to) {
        if (
          from.visibility
          && to.visibility
          && (from.visibility < 0.1 || to.visibility < 0.1)
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        ctx.beginPath();
        ctx.moveTo(from.x * canvas.width, from.y * canvas.height);
        ctx.lineTo(to.x * canvas.width, to.y * canvas.height);
        ctx.stroke();
      }
    }
  }, []);

  const onResults = useCallback((results) => {
    if (
      typeof webcamRef.current !== 'undefined'
      && webcamRef.current !== null
    ) {
      const { video }:any = webcamRef.current;
      const { videoWidth } = video;
      const { videoHeight } = video;

      if (canvasRef.current) {
        // Set canvas width
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
      }

      // video.width = videoWidth;
      // video.height = videoHeight;

      const canvasElement: any = canvasRef.current;
      const canvasCtx = canvasElement.getContext('2d');
      removeLandmarks(results);
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, videoWidth, videoHeight);
      canvasCtx.translate(videoWidth, 0);
      canvasCtx.scale(-1, 1);
      canvasCtx.drawImage(
        results.image,
        0,
        0,
        canvasElement.width,
        canvasElement.height,
      );
      canvasCtx.lineWidth = 5;
      if (results.poseLandmarks) {
        if (results.rightHandLandmarks) {
          canvasCtx.strokeStyle = 'white';
          connect(canvasCtx, [[
            results.poseLandmarks[POSE_LANDMARKS.RIGHT_ELBOW],
            results.rightHandLandmarks[0],
          ]]);
        }
        if (results.leftHandLandmarks) {
          canvasCtx.strokeStyle = 'white';
          connect(canvasCtx, [[
            results.poseLandmarks[POSE_LANDMARKS.LEFT_ELBOW],
            results.leftHandLandmarks[0],
          ]]);
        }
      }

      setCapturedResults(results);
      onStarted();

      try {
        // Pose...
        drawConnectors(
          canvasCtx, results.poseLandmarks, POSE_CONNECTIONS,
          { color: 'white' },
        );
        drawLandmarks(
          canvasCtx,
          Object.values(POSE_LANDMARKS_LEFT)
            .map((index) => results.poseLandmarks[index]),
          { visibilityMin: 0.65, color: 'white', fillColor: 'rgb(255,138,0)' },
        );
        drawLandmarks(
          canvasCtx,
          Object.values(POSE_LANDMARKS_RIGHT)
            .map((index) => results.poseLandmarks[index]),
          { visibilityMin: 0.65, color: 'white', fillColor: 'rgb(0,217,231)' },
        );

        // Hands...
        drawConnectors(
          canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
          { color: 'white' },
        );
        drawLandmarks(canvasCtx, results.rightHandLandmarks, {
          color: 'white',
          fillColor: 'rgb(0,217,231)',
          lineWidth: 2,
          radius: (data) => lerp(Number(data.from?.z), -0.15, 0.1, 10, 1),
        });

        drawConnectors(
          canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
          { color: 'white' },
        );
        drawLandmarks(canvasCtx, results.leftHandLandmarks, {
          color: 'white',
          fillColor: 'rgb(255,138,0)',
          lineWidth: 2,
          radius: (data) => lerp(Number(data.from?.z), -0.15, 0.1, 10, 1),
        });

      // eslint-disable-next-line no-empty
      } catch {}

      canvasCtx.restore();
    } else {
      onStarted();
    }
  }, [connect, removeLandmarks, onStarted]);

  useEffect(() => {
    try {
      let originPosition;
      let targetPosition;

      if (member_selected.target_name === 'Punho') {
        targetPosition = side === 'Direito' ? capturedResults.rightHandLandmarks[member_selected.target_index[0]] : capturedResults.leftHandLandmarks[member_selected.target_index[0]];
      } else {
        targetPosition = capturedResults.poseLandmarks[member_selected.target_index[side === 'Direito' ? 1 : 0]];
      }

      if (member_selected.name === 'Punho') {
        originPosition = side === 'Direito' ? capturedResults.rightHandLandmarks[member_selected.index[0]] : capturedResults.leftHandLandmarks[member_selected.index[0]];
        targetPosition = side === 'Direito' ? capturedResults.rightHandLandmarks[member_selected.target_index[0]] : capturedResults.leftHandLandmarks[member_selected.target_index[0]];
      } else {
        originPosition = capturedResults.poseLandmarks[member_selected.index[side === 'Direito' ? 1 : 0]];
      }

      const angle = getAngle(
        originPosition.x,
        originPosition.y,
        targetPosition.x,
        targetPosition.y,
        (side === 'Direito') ? movement_selected.right : !movement_selected.right,
        side,
        (side === 'Direito') ? movement_selected.is_clockwise : !movement_selected.is_clockwise,
      );

      const thetaAngle = (angle - movement_selected.offset);
      // if (thetaAngle < 0) thetaAngle += 360;
      // if (thetaAngle > 0) thetaAngle -= 360;

      onChangeAngle(thetaAngle.toFixed(2));
    // eslint-disable-next-line no-empty
    } catch { }
  }, [capturedResults, member_selected, movement_selected, side, onChangeAngle]);

  useEffect(() => {
    const holistic = new Holistic({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`,
    });
    holistic.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });
    holistic.onResults(onResults);

    if (
      typeof webcamRef.current !== 'undefined'
    && webcamRef.current !== null
    ) {
      const { video } = webcamRef.current as any;

      const camera = new Camera(video, {
        onFrame: async () => {
          await holistic.send({ image: video });
        },
        width: 640,
        height: 480,
        facingMode: 'environment',
      });

      camera.start();
    }
  }, [onResults]);

  const testeClose = useCallback(async () => {
    // if (
    //   typeof webcamRef.current !== 'undefined'
    // && webcamRef.current !== null
    // ) {
    //   const { video } = webcamRef.current as any;

    //   const stream = video.srcObject;
    //   const tracks = stream.getTracks();

    //   tracks.forEach((track: any) => track.stop());
    //   video.srcObject = null;
    //   console.log('Aqui');
    // }
  }, []);

  return (
    <Container>
      {showCam
     && (
     <>
       <Webcam
         audio={false}
         videoConstraints={{ ...videoConstraints }}
         mirrored
         ref={webcamRef}
         style={{
           height: '100%',
           width: '100%',
           objectFit: 'cover',
           gridColumn: '1 / 1',
           gridRow: '1 / 1',
           borderRadius: '8px 8px 0 0',
         }}
       />
       <canvas
         ref={canvasRef}
         style={{
           height: '100%',
           width: '100%',
           objectFit: 'cover',
           gridColumn: '1 / 1',
           gridRow: '1 / 1',
           borderRadius: '8px 8px 0 0',
           zIndex: 100,
         }}
       />
     </>
     )}
    </Container>
  );
};

export default MPHolistic;
