import styled from 'styled-components';
import ReactSelect from 'react-select';
import { theme } from '../../../../../global/styles/styles';
import Tooltip from '../../../../Tooltip';

export const SelectContainer = styled(ReactSelect)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 34px;
  align-content: center;


  border-radius: 4px;
  border: 1px solid ${theme.colors.secondary60};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  color: ${theme.colors.secondary60};


  .react-select__control {
    top: -5px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

  .react-select__control--is-focused{
    color: ${theme.colors.secondary60};
  }

  .react-select__menu {
    box-shadow: none;
    margin-top: 1px;
  }


  .react-select__option{
    border-bottom: 1px solid ${theme.colors.secondary40};
    height: 40px;
  }


  .react-select__option:hover{
    background-color: ${theme.colors.secondary20};
    cursor: pointer;
  }

  .react-select__option--is-selected{
    background-color: ${theme.colors.secondary20};
    color: ${theme.colors.secondary60};
  }
`;

export const Error = styled(Tooltip)`
  align-self: center;
  height: 20px;
  margin: 0 8px;

  right: 0;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
