import styled from 'styled-components';
import { theme } from '../../global/styles/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;

  padding: 10px 50px;
  border: 1px solid transparent;

  :hover {
    cursor: pointer;
    border-color: ${theme.colors.primary50};
    border-radius: 8px;
  }
`;
