import React, {
  createContext, useContext, useCallback, useState, useMemo,
} from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { Button } from '../../components/forms/Button';

import { theme } from '../../global/styles/styles';

import {
  Container,
  Content,
  Header,
  Title,
  Body,
  Footer,
} from './styles';

interface ModalContextData {
  handleVisible(): void;
  handleDismiss(): void;
  configModal(
    headerTitle: string,
    bodyDescription: string,
    footerHasQuestion?: boolean,
    handleConfirmButton?: Function,
    iconType?: 'delete' | 'warning' | 'power',
    ): void;
}
const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [IconType, setIconType] = useState('warning');
  const [description, setDescription] = useState('Você tem certeza que deseja excluir cliente? Sua ação não poderá ser desfeita.');
  const [hasQuestion, setHasQuestion] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState<Function>(() => {});

  const configIcon = useMemo(() => { // switch (IconType) {
    //   case 'delete':
    //     return DeleteSVG;
    //   case 'power':
    //     return PowerSVG;
    //   default:
    //     return WarningSVG;
    // }
  },
  [IconType]);

  const configModal = useCallback((
    headerTitle: string,
    bodyDescription: string,
    footerHasQuestion = false,
    handleConfirmButton = () => {},
    iconType = 'warning',
  ) => {
    setTitle(headerTitle);
    setDescription(bodyDescription);
    setHasQuestion(footerHasQuestion);
    setHandleConfirm(handleConfirmButton);
    setIconType(iconType);
  }, []);

  const handleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleDismiss = useCallback(() => {
    setVisible(false);
  }, []);

  const handleYes = useCallback(() => {
    setVisible(false);
    handleConfirm();
  }, [setVisible, handleConfirm]);

  return (
    <ModalContext.Provider value={{
      handleVisible,
      handleDismiss,
      configModal,
    }}
    >
      {visible && (
      <Container>
        <Content>
          <Header>
            <Title>
              <h1>{title}</h1>
            </Title>
            {/* <img src={AiOutlineWarning} alt="icon" /> */}
          </Header>
          <Body>
            <h1>{description}</h1>
          </Body>
          <Footer>
            {!hasQuestion ? <Button onClick={handleDismiss}>OK</Button>
              : (
                <>
                  <Button
                    filledColor={theme.colors.secondary00}
                    outlineColor={theme.colors.primary50}
                    onClick={handleYes}
                  >
                    Sim
                  </Button>
                  <Button onClick={handleDismiss}>Cancelar</Button>
                </>
              )}
          </Footer>
        </Content>
      </Container>
      )}
      {children}
    </ModalContext.Provider>
  );
};

// função para retornar o contexto de autenticação da aplicação
function useModal(): ModalContextData {
  // atribui o contexto ao hook
  const context = useContext(ModalContext);

  // se não existir retorna um erro
  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  // retorna o contexto
  return context;
}

export { ModalProvider, useModal };
