import React, {
  useCallback, useEffect, useState,
} from 'react';

import {
  Container,
  ButtonArea,
  AlertArea,
  Alert,
  StartButton,
  StartArea,
  Buttons,
} from './styles';

import MPHolistic from '../MPHolistic';
import { BodyPartProps, MovementTypeProps } from '../../pages/Evaluate/data/options';
import { StartedAlert } from './StartedAlert';
// import { ChooseDevice } from './ChooseDevice';

interface CameraContentProps {
  can_start: boolean;
  onSave?: Function;
  onReset?: Function;
  member_selected: BodyPartProps;
  movement_selected: MovementTypeProps;
  side: string;
  onChangeAngle: Function;
}

// interface DeviceProps {
//   [key: string]: any;
//   deviceId: string
//   groupId: string
//   label: string
// }

export const CameraContent: React.FC<CameraContentProps> = (
  {
    can_start,
    onSave = () => {},
    onReset = () => {},
    member_selected,
    movement_selected,
    side,
    onChangeAngle,
  },
) => {
  // const [deviceId, setDeviceId] = useState('');
  // const [devices, setDevices] = useState<DeviceProps[]>([]);

  const [alerted, setAlerted] = useState(false);

  const [canStart, setCanStart] = useState(can_start);
  const [started, setStarted] = useState(false);
  const [loadingMPH, setLoadingMPH] = useState(true);
  // const [choosingDevice, setChoosingDevice] = useState(false);

  // const handleCloseDeviceModal = useCallback(() => {
  //   setChoosingDevice(false);
  // }, []);

  const handleStartDetectPose = useCallback(() => {
    if (!canStart) return;

    setStarted(true);
    setCanStart(true);
  }, [canStart]);

  const onStart = useCallback(() => {
    setLoadingMPH(false);
  }, []);

  useEffect(() => {
    setCanStart(can_start);
  }, [can_start]);

  // const handleDevices = useCallback(
  //   (mediaDevices) => {
  //     setDevices(mediaDevices.filter(({ kind } :any) => kind === 'videoinput'));
  //   },
  //   [setDevices],
  // );

  // const devicesOptions = useMemo(() => devices.map((d) => {
  //   setDeviceId(d.deviceId);
  //   return {
  //     value: d.deviceId,
  //     label: d.label,
  //   };
  // }), [devices]);

  // const handleDeviceChange = useCallback((inputValue) => {
  //   setDeviceId(inputValue.value);
  // }, []);

  // const handleOpenDeviceModal = useCallback(() => {
  //   setChoosingDevice(true);
  // }, []);

  // useEffect(
  //   () => {
  //     navigator.mediaDevices.enumerateDevices().then(handleDevices);
  //   },
  //   [handleDevices],
  // );

  return (
    <Container>
      {started && (
      <MPHolistic
        member_selected={member_selected}
        movement_selected={movement_selected}
        side={side}
        onChangeAngle={onChangeAngle}
        onStarted={onStart}
      />
      )}
      {alerted && (
      <AlertArea>
        <Alert>Verificar Postura</Alert>
      </AlertArea>
      )}
      { !started && (
        <StartArea>
          <StartButton
            type="button"
            onClick={handleStartDetectPose}
            disable={!canStart}
          >
            Iniciar Avaliação
          </StartButton>
        </StartArea>
      )}
      <ButtonArea>
        { started && (
        <Buttons>
          <button className="left-button" type="button" onClick={() => onReset()}>
            <span className="material-icons">undo</span>
            Refazer
          </button>
          <button className="right-button" type="button" onClick={() => onSave()}>
            Salvar
          </button>
        </Buttons>
        )}
      </ButtonArea>
      {/* {choosingDevice && (
      <ChooseDevice
        title="Escolha o seu dispositivo"
        options={devicesOptions}
        onClose={devices.length === 0 ? handleCloseDeviceModal : handleStartDetectPose}
        onChange={handleDeviceChange}
      />
      )} */}
      {started && loadingMPH
        && (
        <StartedAlert
          title="Carregando esqueleto"
          description="Estamos carregando o esqueleto por favor aguarde um momento..."
        />
        )}
    </Container>
  );
};
