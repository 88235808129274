import styled from 'styled-components';
import { theme } from '../../../global/styles/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 64px;
  max-height: 64px;

  text-align: left;

  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${theme.colors.secondary100};
  }
`;
