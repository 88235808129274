import React, { ChangeEvent, useCallback } from 'react';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Avatar from '../Avatar';

import {
  Container,
  TextArea,
} from './styles';

export const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch(`/users/${user.id}/avatar`, data).then((response) => {
          updateUser(response.data);
        });
      }
    }, [updateUser, user],
  );

  return (
    <Container>
      <Avatar
        avatar_url={user ? user.avatar_url : ''}
        alt_name={user ? user.name : ''}
        handleChange={handleAvatarChange}
        width={48}
        height={48}
        edit
      />
      <TextArea>
        <h1>{user ? user.name : ''}</h1>
        <h1>{user ? user.role : ''}</h1>
      </TextArea>
    </Container>
  );
};
