import { MdOutlineLocalHospital, MdOutlineAssessment, MdPeopleOutline } from 'react-icons/md';
import { IconType } from 'react-icons';

interface IItemsData {
  id: string;
  title: string;
  path: string;
  icon: IconType;
}

export const ItemsData: IItemsData[] = [
  {
    id: '1',
    title: 'Pacientes',
    path: '/pacientes',
    icon: MdPeopleOutline,
  },
  {
    id: '2',
    title: 'Avaliar',
    path: '/avaliar',
    icon: MdOutlineLocalHospital,
  },
  {
    id: '3',
    title: 'Relatórios',
    path: '/relatorios',
    icon: MdOutlineAssessment,
  },
];
