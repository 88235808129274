import React from 'react';

import {
  Container,
  Content,
  Header,
  Title,
  Body,
  Footer,
} from './styles';

interface StartedAlertProps {
  title: string;
  description: string;
}

export const StartedAlert: React.FC<StartedAlertProps> = ({
  title,
  description,
}) => (
  <Container>
    <Content>
      <Header>
        <Title>
          <h1>{title}</h1>
        </Title>
      </Header>
      <Body>
        <h1>{description}</h1>
      </Body>
      <Footer />
    </Content>
  </Container>
);
