import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, useLocation } from 'react-router-dom';

import Route from './Route';
import { SignIn } from '../pages/SignIn';
import { ForgotPassword } from '../pages/ForgotPassword';
import { Evaluate } from '../pages/Evaluate';
import { Reports } from '../pages/Reports';
import { ListPatients } from '../pages/Patients/ListPatients';
import SideBarMenu from '../components/SideBarMenu';

const Routes: React.FC = () => {
  const [pathname, setPathname] = useState('/');

  return (
    <>
      <BrowserRouter basename="/goniotech">
        {
          pathname !== '/'
          && pathname !== '/forgot'
          && pathname !== '/404'
          && !pathname.includes('resetpassword')
          && <SideBarMenu />
        }
        <Switch>
          <Route exact setPathname={setPathname} path="/" component={SignIn} />
          <Route exact setPathname={setPathname} path="/forgot" component={ForgotPassword} />
          <Route exact setPathname={setPathname} path="/avaliar" component={Evaluate} isPrivate />
          <Route exact setPathname={setPathname} path="/relatorios" component={Reports} isPrivate />
          <Route exact setPathname={setPathname} path="/pacientes" component={ListPatients} isPrivate />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
