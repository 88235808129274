import { createGlobalStyle } from 'styled-components';
import { theme } from './styles';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: ${theme.colors.secondary00};
    color: ${theme.colors.secondary100};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: ${theme.fonts.titleText}, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
  }

  div, h1, h2, h3, h4, h5, h6, strong , label, textarea, select {
    font-family: ${theme.fonts.titleText}, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    text-overflow: ellipsis;
  }

  div, ul, textarea, select, section, form {

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;

    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;

    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.secondary40};
      border-radius: 2px;

    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.secondary60};
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }
  }

  button {
    cursor: pointer;
  }
`;
