import styled, { css } from 'styled-components';
import { theme } from '../../../global/styles/styles';
import Tooltip from '../../Tooltip';

interface ContainerPros {
  isErrored: boolean;
  grid_column?:string;
}

export const Container = styled.div<ContainerPros>`
  width: 100%;
  flex-direction: column;

  min-height: 64px;
  max-height: 64px;

  margin-bottom: 16px;

  text-align: left;

  > div {
    border-radius: 4px;
    border: 1px solid ${theme.colors.secondary60};
  }


  h1 {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: normal;
    color: ${theme.colors.secondary80};
  }

  input {
    width: 100%;
    height: 34px;
    flex: 1;
    background: transparent;
    border: 0;
    color: ${theme.colors.secondary60};
    padding: 0 18px;
    font-size: 14px;

    span {
      padding: 0 18px;
    }

    &::placeholder {
      color: ${theme.colors.secondary60};
    }
  }

  svg {
    margin-right: 16px;
  }

  ${(props) => props.isErrored && css`
    border-color: ${theme.colors.error};
  `}

  ${(props) => props.grid_column && css`
    grid-column: ${props.grid_column};
  `}
`;

export const Error = styled(Tooltip)`
  align-self: center;
  height: 20px;
  margin: 0 8px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
