import styled, { css } from 'styled-components';
import { theme } from '../../global/styles/styles';
import Silhouette from '../../assets/silhouette.png';

interface StartButtonProps {
  disable?: boolean;
}

export const Container = styled.div`
  border: 1px solid ${theme.colors.secondary20};
  border-radius: 8px;
  height: 720px;
  width: 480px;

  background: url(${Silhouette}) no-repeat center;
  background-size: 50%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  flex-direction: column;
  justify-content:space-between;
`;

export const ButtonArea = styled.div`
  background-color: ${theme.colors.secondary20};
  width: 100%;
  height: 53px;

  border-radius: 0 0 8px 8px;
`;

export const Buttons = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content:space-between;

  border-radius: 0 0 8px 8px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;

    max-width: calc(50% - .5px);
    flex-grow: 1;

    border:  0;
    border-radius: 0 0 8px 0;
    background-color: ${theme.colors.primary50};

    font-weight: normal;
    text-align: center;
    text-transform: uppercase;

    span {
      margin-right: 19px;
    }
  }

  .left-button {
    border-radius: 0 0 0 8px;
  }

  .right-button {
    border-radius: 0 0 8px 0;
  }


  ${ButtonArea} && {
    background-color:  #FFF;
  }
`;

export const AlertArea = styled.div`
  display: flex;
  height: 53px;
  justify-content: center;
  align-items: center;
`;

export const Alert = styled.div`
  width: 50%;
  height: 32px;
  border: 1px solid ${theme.colors.error};
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  color:  ${theme.colors.error};
  text-transform: uppercase;
`;

export const StartArea = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StartButton = styled.button<StartButtonProps>`
  width: 50%;
  height: 48px;

  text-transform: uppercase;

  border-radius: 4px;
  border: 0;
  background-color: ${theme.colors.primary100};
  color: #FFF;

  ${(props) => props.disable && css`
    background-color: #FFF;
    color: ${theme.colors.secondary60};
    border: 1px solid ${theme.colors.secondary60};
  `}
`;
