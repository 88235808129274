import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
`;
