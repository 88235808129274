import React from 'react';

import {
  Container,
  Content,
} from './styles';
import { Radio } from './components/Radio';

export interface IOptionsProps {
  id: string;
  option: string;
  correct: boolean;
  question_id: string;
}

export interface IRadioOptionsProps {
  id: string,
  question: string,
  essay_question: boolean,
  value: string,
  options: IOptionsProps[],
}

interface InputProps {
  options: IRadioOptionsProps;
  name: string;
  label: string;
}

export const RadioInput: React.FC<InputProps> = ({
  name, label, options,
}) => (
  <Container>
    {label && <h1>{label}</h1>}
    <Content>
      <Radio
        defaultValue={options.options[0].option}
        name={name}
        options={options.options}
      />
    </Content>

  </Container>
);
