import React, { useCallback, useState } from 'react';

import { IoMdLogOut } from 'react-icons/io';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ItemsData } from './components/data/ItemsData';

import MenuItem from './components/MenuItem';

import {
  TopBar,
  SidebarContainer,
  HeadContent,
  BodyContent,
  FooterContent,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { Profile } from '../Profile';

const SideBarMenu: React.FC = () => {
  const [isSignOut, setIsSignOut] = useState(false);
  const { signOut } = useAuth();
  const history = useHistory();

  const doSignOut = useCallback(async () => {
    history.push('/');
    await signOut();
  }, [signOut, history]);

  return (
    <>
      <TopBar />
      <SidebarContainer>
        <HeadContent>
          <Profile />
        </HeadContent>

        <BodyContent>
          {ItemsData.map((menu) => (
            <MenuItem
              key={menu.id}
              title={menu.title}
              icon={menu.icon}
              path={menu.path}
            />
          ))}
        </BodyContent>
        <FooterContent>
          <MenuItem
            title="Logout"
            icon={IoMdLogOut}
            path="/"
            onClick={doSignOut}
          />
        </FooterContent>
      </SidebarContainer>
    </>
  );
};

export default SideBarMenu;
