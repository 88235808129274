import React, {
  useEffect, useRef,
} from 'react';

import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import {
  Container,
  Error,
} from './styles';

interface InputAreaProps {
  name: string;
  rows: number;
  cols?: number;
  containerStyle?: object;
  label: string;
  grid_column?: string;
  label_color?: string;
}

export const InputArea: React.FC<InputAreaProps> = ({
  name, containerStyle = {}, label, rows, cols, grid_column, label_color, ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // define o hook de do campo de formulario
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  // chama o hooks de efeito para registrar o campo do formulario
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      label_color={label_color}
      grid_column={grid_column}
      style={containerStyle}
      isErrored={!!error}
      isFilled={false}
      isFocused={false}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h1>{label}</h1>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
      <textarea
        ref={inputRef}
        rows={rows}
        cols={cols}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
};
