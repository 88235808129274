import React, {
  useEffect, useRef, InputHTMLAttributes, Fragment,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import {
  Container,
  Error,
} from './styles';
import { IOptionsProps } from '../..';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: IOptionsProps[];
}

export const Radio: React.FC<Props> = ({
  name, options, ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const {
    fieldName, registerField, defaultValue = '', error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => refs.find((ref) => ref.checked)?.value || '',
      setValue: (refs: HTMLInputElement[], id: string) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      {options.map((option, index) => (
        <Fragment key={option.id}>
          <input
            defaultChecked={defaultValue === option.id}
            ref={(ref) => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            name={fieldName}
            value={option.option}
            type="radio"
            id={String(option.id)}
            {...rest}
          />
          <label htmlFor={String(option.id)} key={option.id}>
            {option.option}
          </label>
        </Fragment>
      ))}

      {error && (
      <Error title={error}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
      )}
    </Container>

  );
};
