import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import { Input } from '../../../components/forms/Input';
import { ClientButton } from '../components/ClientButton';

import {
  Container,
  ButtonArea,
  ListContent,
  TableHeader,
  BodyContent,
  SearchArea,
} from './styles';
import api from '../../../services/api';
import { cpfMasked, removeMask } from '../../../utils/masks';
import { Button } from '../../../components/forms/Button';
import { theme } from '../../../global/styles/styles';
import { PatientPanel } from '../PatientPanel';
import { useModal } from '../../../hooks/Modal';

export interface PatientProps {
  id?: string;
  cpf?: string;
  birth_date?: string | Date;
  name: string;
  created_at: string | Date;
  last_avaliation?: string | Date;
  last_rate: string | Date;
}

export const ListPatients: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [showPatientPanel, setShowPatientPanel] = useState(false);

  const [currentPatient, setCurrentPatient] = useState({} as PatientProps);
  const [listPatients, setListPatients] = useState<PatientProps[]>([]);
  const [filteredPatients, setFilteredPatients] = useState<PatientProps[]>([]);

  const configPatients = useCallback((list: PatientProps[]) => {
    const temp = list.map((client) => {
      const c = {
        ...client,
        cpf: cpfMasked(client.cpf as string),
        birth_date: new Date(client.birth_date as string).toLocaleDateString(),
        created_at: new Date(client.created_at as string).toLocaleDateString(),
        last_avaliation: client.birth_date ? new Date(client.birth_date as string).toLocaleDateString() : '',
      };

      return c;
    });

    setListPatients(temp);
    setFilteredPatients(temp);
  }, []);

  const handleSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const filtered = listPatients.filter(
      (client) => client.name.toLowerCase().includes(event.currentTarget.value.toLowerCase()),
    );
    setFilteredPatients(filtered);
  }, [listPatients]);

  const handleShowPatientPanel = useCallback((show: boolean) => {
    setShowPatientPanel(show);

    if (!show) {
      setCurrentPatient({} as PatientProps);
      api.get('/patient/').then((response) => {
        const list: PatientProps[] = response.data;
        configPatients(list);
      });
    }
  }, [configPatients]);

  useEffect(() => {
    api.get('/patient/').then((response) => {
      const list: PatientProps[] = response.data;
      configPatients(list);
    });
  }, [configPatients]);

  return (
    <Container>
      {!showPatientPanel && (
      <>
        <SearchArea>
          <Form ref={formRef} onSubmit={handleSearch}>
            <Input
              name="search"
              onChange={handleSearch}
              label=""
              placeholder="Pesquisar"
              grid_column="1 / 3"
            />
            <ButtonArea>
              <Button
                outlineColor={theme.colors.primary50}
                onClick={() => handleShowPatientPanel(true)}
              >
                Adicionar
              </Button>
            </ButtonArea>
          </Form>
        </SearchArea>
        <ListContent>
          <TableHeader>
            <h1>Nome</h1>
            <h1>CPF</h1>
            <h1>Data de Nascimento</h1>
            <h1>Cadastrado em</h1>
          </TableHeader>
          <BodyContent>
            {filteredPatients.map((item) => (
              <ClientButton
                key={item.name}
                name={item.name}
                cpf={item.cpf as string}
                created_at={item.created_at}
                birth_date={item.birth_date as string}
              />
            ))}
          </BodyContent>
        </ListContent>
      </>
      )}

      {showPatientPanel && (
      <PatientPanel
        onClose={() => setShowPatientPanel(false)}
        patient={currentPatient}
      />
      )}
    </Container>
  );
};
