import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import {
  Container,
} from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  filledColor?: string;
  outlineColor?: string;
  iconSize?: number;
  iconColor?: string;
  color?: string;
  borderStyles?: string;
  hasShadow?: boolean;
  height?: number;
  icon?: ReactNode;
  grid_column?: string;
  alignItems?: 'center' | 'start';
}

export const Button: React.FC<ButtonProps> = (
  {
    children,
    loading = false,
    hasShadow = false,
    filledColor = '',
    outlineColor = '',
    iconColor = '',
    color = '',
    height = 44,
    borderStyles = 'solid',
    alignItems = 'center',
    grid_column,
    icon: Icon,
    ...rest
  },
) => (
  <Container
    type="button"
    grid_column={grid_column}
    color={color}
    filledColor={filledColor}
    outlineColor={outlineColor}
    hasShadow={hasShadow}
    height={height}
    iconColor={iconColor}
    alignItems={alignItems}
    borderStyles={borderStyles}
    disabled={loading}
    {...rest}
  >
    {loading ? 'Carregando..' : children}
    {!loading && Icon}

  </Container>
);
