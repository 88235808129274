import { IRadioOptionsProps } from '../../../components/forms/RadioInput';

export interface MovementTypeProps {
  name: string;
  right: boolean, // Se verdadeiro, o 0° projetado no plano ficará a direita do canvas,
  // indica o sentido da rotação em relação ao 0°, define se o 90° ficará acima ou abaixo no plano
  is_clockwise: boolean;
  offset: number; // fator de correção para posicionar o 0 em cima ou em baixo do canvas
  min_angle: number,
  max_angle: number,
}

export interface BodyPartProps {
  name: string;
  target_name: string;
  index: number[],
  target_index: number[],
  movementType: MovementTypeProps[];
}

export const bodyParts: BodyPartProps[] = [
  {
    name: 'Ombro',
    target_name: 'Cotovelo',
    index: [11, 12],
    target_index: [13, 14],
    movementType: [
      {
        name: 'Flexão',
        offset: 90,
        right: true,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 180,
      },
      {
        name: 'Extensão',
        offset: 90,
        right: false,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 45,
      },
      {
        name: 'Adução',
        offset: 90,
        right: true,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 40,
      },
      {
        name: 'Abdução',
        offset: 90,
        right: true,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 180,
      },
    ],
  },
  {
    name: 'Cotovelo',
    target_name: 'Punho',
    index: [13, 14],
    target_index: [0],
    movementType: [
      {
        name: 'Flexão',
        offset: 90,
        right: true,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 145,
      },
      {
        name: 'Extensão',
        offset: 90,
        right: true,
        is_clockwise: false,
        min_angle: 145,
        max_angle: 0,
      },
    ],
  },
  {
    name: 'Punho',
    target_name: '',
    index: [0],
    target_index: [12],
    movementType: [
      {
        name: 'Flexão',
        offset: 0,
        right: false,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 90,
      },
      {
        name: 'Extensão',
        offset: 0,
        right: false,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 70,
      },
      {
        name: 'Adução',
        offset: 0,
        right: false,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 45,
      },
      {
        name: 'Abdução',
        offset: 0,
        right: false,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 20,
      },
    ],
  },
  {
    name: 'Quadril',
    target_name: 'Joelho',
    index: [23, 24],
    target_index: [25, 26],
    movementType: [
      {
        name: 'Flexão',
        offset: 0,
        right: false,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 125,
      },
      {
        name: 'Extensão',
        offset: 0,
        right: true,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 10,
      },
      {
        name: 'Adução',
        offset: 0,
        right: true,
        is_clockwise: true,
        min_angle: 0,
        max_angle: 15,
      },
      {
        name: 'Abdução',
        offset: 90,
        right: true,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 45,
      },
    ],
  },
  {
    name: 'Joelho',
    target_name: 'Tornozelo',
    index: [25, 26],
    target_index: [27, 28],
    movementType: [
      {
        name: 'Flexão',
        offset: 0,
        right: true,
        is_clockwise: false,
        min_angle: 0,
        max_angle: 140,
      },
      {
        name: 'Extensão',
        offset: 90,
        right: true,
        is_clockwise: true,
        min_angle: 145,
        max_angle: 0,
      },
    ],
  },
];

export const membersSides: IRadioOptionsProps = {
  id: 'side',
  question: 'lado',
  essay_question: false,
  value: 'Direito',
  options: [
    {
      id: 'Direito',
      option: 'Direito',
      correct: false,
      question_id: 'side',
    },
    {
      id: 'Esquerdo',
      option: 'Esquerdo',
      correct: false,
      question_id: 'side',
    },
  ],
};
