import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  ChangeEvent,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
import { isDate } from 'moment';
import { Button } from '../../components/forms/Button';
import { FormSection } from '../../components/forms/FomrSection';
import { Input } from '../../components/forms/Input';
import { RadioInput } from '../../components/forms/RadioInput';
import { SelectBox } from '../../components/forms/SelectBox';
import { SelectOptionsProps } from '../../components/forms/SelectBox/components/Select';
import { InputArea } from '../../components/forms/InputArea';
import {
  BodyPartProps, bodyParts, membersSides, MovementTypeProps,
} from './data/options';

import {
  Container,
  Content,
  FormContent,
  Header,
  Title,
  Body,
  Step,
} from './styles';
import { StepInput } from '../../components/forms/StepInput';
import { DateInput } from '../../components/forms/DateInput';
import { CameraContent } from '../../components/CameraContent';
import { cpfMasked, removeMask } from '../../utils/masks';
import getValidationErros from '../../utils/getValidationErrors';
import { MovementSection, MovementSectionProps } from '../../components/MovementSection';
import { useModal } from '../../hooks/Modal';
import { theme } from '../../global/styles/styles';
import api from '../../services/api';
import { PatientProps } from '../Patients/ListPatients';

interface DataFormProps {
  cpf: string;
  name: string;
  birth_date: Date;
  weight: number;
  height: number;
  imc: number;
  inspection: string;
  palpation: string;
  heart_rate: number;
  respiratory_frequency: number;
  blood_pressure: number;
  spo2: number;

  right_upper_limb: number;
  left_upper_limb: number;
  right_lower_limb: number;
  left_lower_limb: number;

  member_name: string;
  movement_type: string;
  side: string;
  initial_position: number;
  end_position: number;
  adm_value: number;
  default_pattern: string;
  eva_value: number;
}

interface MovementTypeDataProps {
  limb: string;
  movement_type: string;
  side: string;

  initial_position: number;
  final_position: number;

  adm: number;
  normal_pattern: string | undefined;

  eva: string;
}

const titles = [
  'Informações do Paciente',
  'Avaliação',
  'Resumo',
];

export const Evaluate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [currentData, setCurrentData] = useState({} as DataFormProps);
  const [patient, setPatient] = useState({} as PatientProps);
  const [allMovements, setAllMovements] = useState<MovementSectionProps[]>([]);
  const [currentMovementType, setCurrentMovementType] = useState({} as MovementSectionProps);
  const [currentInputAngle, setCurrentInputAngle] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSide, setCurrentSide] = useState('Direito');
  const [imcValue, setImcValue] = useState('0');
  const [title, setTitle] = useState(titles[0]);
  const [selectedMember, setSelectedMember] = useState(bodyParts[0]);
  const [selectedMovement, setSelectedMovement] = useState(bodyParts[0].movementType[0]);
  const { configModal, handleVisible } = useModal();

  const [initialPos, setInitialPos] = useState(0);
  const [finalPos, setFinalPos] = useState(0);

  const saveAngle = useCallback(() => {
    if (currentInputAngle >= 0 && currentInputAngle < 2) {
      setCurrentInputAngle(currentInputAngle + 1);
    } else if (currentInputAngle === 2) {
      // colocar a opção de salvar os valores coletados

      if (formRef.current) {
        const {
          side,
          initial_position,
          end_position,
          adm_value,
          default_pattern,
          eva_value,
        } = formRef.current.getData() as DataFormProps;

        const movementSection: MovementSectionProps = {
          memberName: selectedMember.name,
          movementType: selectedMovement.name,
          sideValue: side,
          initialPosition: initial_position,
          finalPosition: end_position,
          admValue: adm_value,
          degree: default_pattern,
          eva: eva_value,
        };

        const temp = allMovements;
        temp.push(movementSection);

        setAllMovements(temp);
      }

      setInitialPos(0);
      setFinalPos(0);
      setCurrentInputAngle(0);
    }
  }, [currentInputAngle, allMovements, selectedMovement, selectedMember]);

  const removeMovement = useCallback(async () => {
    try {
      allMovements.splice(allMovements.indexOf(currentMovementType, 1));
    } catch {
      configModal('Erro na requisição', 'Não foi possivel remover a avaliação, tente novamente');
      handleVisible();
    }
  }, [allMovements, currentMovementType, configModal, handleVisible]);

  const handleDeleteMovement = useCallback(async (item : MovementSectionProps) => {
    setCurrentMovementType(item);
    handleVisible();
  }, [handleVisible]);

  useEffect(() => {
    configModal(
      'Deletar avaliação do membro', 'Você deseja excluir a avaliação selecionada?',
      true,
      () => removeMovement,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMovementType]);

  const memberOptions: SelectOptionsProps[] = useMemo(() => bodyParts.map((part) => ({
    value: part.name,
    label: part.name,
  })), []);

  const movementTypeOptions: SelectOptionsProps[] = useMemo(() => {
    const member = bodyParts.find((part) => part.name === selectedMember.name);

    return member ? member.movementType.map((movement) => ({
      value: movement.name,
      label: movement.name,
    })) : [] as SelectOptionsProps[];
  }, [selectedMember]);

  const degree = useMemo(() => `${selectedMovement.min_angle}-${selectedMovement.max_angle}`,
    [selectedMovement]);

  const admValue = useMemo(() => Math.abs(finalPos - initialPos).toFixed(2),
    [initialPos, finalPos]);

  const onFormChange = useCallback(() => {
    let value = '0';
    if (formRef.current) {
      const { height, weight, side } = formRef.current.getData() as DataFormProps;

      if (weight && height) {
        value = ((weight) / (height ** 2)).toFixed(2);
      }

      setCurrentSide(side);
    }

    setImcValue(value);
  }, []);

  const handleMemberChange = useCallback((inputValue) => {
    const member = bodyParts.find((part) => part.name === inputValue.value);
    setSelectedMember(member || {} as BodyPartProps);
    setSelectedMovement(member?.movementType[0] || {} as MovementTypeProps);
  }, []);

  const onChangeAngle = useCallback((value) => {
    if (currentInputAngle === 0) {
      setInitialPos(Number(value));
    } else if (currentInputAngle === 1) {
      setFinalPos(Number(value));
    }
  }, [currentInputAngle]);

  const handleMovementChange = useCallback((inputValue) => {
    const movement = selectedMember.movementType.find((type) => type.name === inputValue.value);
    setSelectedMovement(movement || {} as MovementTypeProps);
  }, [selectedMember]);

  const handleBackCurrentInputAngle = useCallback(() => {
    if (currentInputAngle > 0) {
      setCurrentInputAngle(currentInputAngle - 1);
    }
  }, [currentInputAngle]);

  const nextStep = useCallback(async (data: DataFormProps) => {
    if (currentStep <= 2) {
      try {
        formRef.current?.setErrors({});

        if (allMovements.length <= 0 && currentStep === 1) {
          configModal(
            'Nenhuma avaliação salva', 'É preciso salvar ao menos uma avaliação de um membro para poder prosseguir',
            false,
            () => {},
          );
          handleVisible();
          return;
        }

        let schema;
        if (currentStep === 0) {
          schema = Yup.object().shape({
            cpf: Yup.string()
              .required('CPF obrigatório')
              .test(
                'is-cpf',
                'Digite um CPF válido',
                (value) => validateCPF(value as string),
              ),
            name: Yup.string()
              .required('Nome obrigatório'),
            birth_date: Yup.string().test(
              'is-Date',
              'Digite uma Data válida',
              (value) => isDate(new Date(value as string)),
            ).required('Data de início obrigatória'),
            weight: Yup.string()
              .required('Peso obrigatório'),
            height: Yup.string()
              .required('Altura obrigatória'),
            heart_rate: Yup.string()
              .required('Frequência cardíaca obrigatória'),
            respiratory_frequency: Yup.string()
              .required('Frequência respiratória obrigatória'),
            blood_pressure: Yup.string()
              .required('Pressão arterial obrigatória'),
            spo2: Yup.string()
              .required('Saturação obrigatória'),
          });
        } else if (currentStep === 1) {
          schema = Yup.object().shape({
            right_upper_limb: Yup.string()
              .required('Comprimento superior direito obrigatório'),
            left_upper_limb: Yup.string()
              .required('Comprimento superior esquerdo obrigatório'),
            right_lower_limb: Yup.string()
              .required('Comprimento inferior direito obrigatório'),
            left_lower_limb: Yup.string()
              .required('Comprimento inferior esquerdo obrigatório'),
          });
        } else {
          schema = Yup.object().shape({

          });
        }

        // verifica a validação do esquema criado
        await schema.validate(data, {
          abortEarly: false,
        });

        if (currentStep === 0) {
          const temp: DataFormProps = {
            ...data,
          };

          setCurrentData(temp);
        } else if (currentStep === 1) {
          const temp: DataFormProps = {
            ...currentData,
            right_upper_limb: data.right_upper_limb,
            left_upper_limb: data.left_upper_limb,
            right_lower_limb: data.right_lower_limb,
            left_lower_limb: data.left_lower_limb,
          };

          setCurrentData(temp);
        } else {
          const movementsTypes: MovementTypeDataProps[] = allMovements.map((type) => ({
            limb: type.memberName,
            movement_type: type.movementType,
            side: type.sideValue,

            initial_position: type.initialPosition,
            final_position: type.finalPosition,

            adm: type.admValue,
            normal_pattern: type.degree,

            eva: type.memberName,
          }));

          // chama o metodo de sign in
          await api.post('/avaliation/',
            {
              ...currentData,
              patient_id: patient.id,
              status: 'Ativo',
              avaliation_data: JSON.stringify(movementsTypes),
            }).catch((err) => {
            configModal('Erro na requisição', err.response.data.message);
            handleVisible();
          }).then((response) => {
            if (response?.status && response.status >= 200 && response.status <= 299) {
              configModal('Avaliação cadastrada', 'A avaliação foi cadastrada com sucesso', false, () => handleBackCurrentInputAngle());
              handleVisible();
            }
          });
        }
      } catch (err) {
        // se o erro for uma instancia de validação
        if (err instanceof Yup.ValidationError) {
          // se houver erros na validação aplica os mesmo na tela
          const erros = getValidationErros(err);
          // salva os erros no formulario
          formRef.current?.setErrors(erros);

          return;
        }
      }

      if (currentStep < 2) {
        const tempStep = currentStep + 1;

        setTitle(titles[tempStep]);
        setCurrentStep(tempStep);
      }
    }
  }, [
    currentStep,
    allMovements,
    configModal,
    handleVisible,
    patient,
    currentData,
    setCurrentData,
    handleBackCurrentInputAngle,
  ]);

  const configPatient = useCallback((data: any) => {
    const tempPatient: PatientProps = data;

    tempPatient.birth_date = new Date(tempPatient.birth_date as string);

    setPatient(tempPatient);
  }, []);

  const handlePatientChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.maxLength = 14;
    let { value } = e.currentTarget;
    value = cpfMasked(value);
    e.currentTarget.value = value;

    if (e.currentTarget.value.length >= 14) {
      api.get(`/patient/${e.currentTarget.value.replace(/\D/g, '')}`).then((response) => {
        configPatient(response.data);

        if (!response.data) {
          configModal('Paciente Não Encontrado', 'O paciente digitado não foi encontrado ou não existe, por favor digite o cpf de um patiente já cadastrado');
          handleVisible();
        }
      });
    } else (setPatient({} as PatientProps));
  }, [configModal, handleVisible, configPatient]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Container>
      <Content>
        <CameraContent
          can_start={currentStep >= 1}
          onSave={saveAngle}
          onReset={handleBackCurrentInputAngle}
          member_selected={selectedMember}
          movement_selected={selectedMovement}
          side={currentSide}
          onChangeAngle={onChangeAngle}
        />
        <FormContent>
          <Header>
            <Title>{title}</Title>
          </Header>
          <Body>
            <Form ref={formRef} initialData={{ side: 'Direito', ...patient }} onSubmit={nextStep} onChange={onFormChange}>

              { currentStep === 0 && (
                <Step>
                  <FormSection title="DADOS PACIENTE">
                    <Input name="cpf" label="CPF" placeholder="Digite o cpf" grid_column="1 / 3" mask="cpf" onChange={handlePatientChange} />
                    <Input name="name" label="Nome" placeholder="Digite o nome do paciente" grid_column="3 / 5" disabled />
                    <DateInput name="birth_date" label="Data de nascimento" placeholder="DD/MM/AAAA" grid_column="1 / 2" newValue={patient.birth_date} disabled />
                    <Input name="weight" label="Peso" placeholder="0" grid_column="2 / 3" mask="alpha-numeric" />
                    <Input name="height" label="Altura" placeholder="0" grid_column="3 / 4" mask="alpha-numeric" />
                    <Input name="imc" label="IMC" placeholder="0" grid_column="4 / 5" disable value={imcValue} />

                    <InputArea name="inspection" label="Inspeção Estática/Dinâmica" rows={4} grid_column="1 / 3" />
                    <InputArea name="palpation" label="Palpação" rows={4} grid_column="3 / 5" />
                  </FormSection>

                  <FormSection title="SINAIS VITAIS">
                    <Input name="heart_rate" label="Frequência cardíaca" placeholder="0" grid_column="1 / 3" />
                    <Input name="respiratory_frequency" label="Frequência respiratória" placeholder="0" grid_column="3 / 5" />
                    <Input name="blood_pressure" label="Pressão Arterial" placeholder="0" grid_column="1 / 3" />
                    <Input name="spo2" label="SPO2" placeholder="0" grid_column="3 / 5" />
                  </FormSection>
                </Step>
              )}

              { currentStep === 1 && (
                <Step>
                  <FormSection title="COMPRIMENTO DO MEMBRO" grid_template_column="1fr 1fr 1fr">
                    <Input name="right_upper_limb" label="Superior Direito (cm)" placeholder="0" grid_column="1 / 2" mask="alpha-numeric" />
                    <Input name="left_upper_limb" label="Superior Esquerdo (cm)" placeholder="0" grid_column="2 / 3" mask="alpha-numeric" />
                    <Input name="right_lower_limb" label="Inferior Direito (cm)" placeholder="0" grid_column="1 / 2" mask="alpha-numeric" />
                    <Input name="left_lower_limb" label="Inferior Esquerdo (cm)" placeholder="0" grid_column="2 / 3" mask="alpha-numeric" />
                  </FormSection>

                  <FormSection title="SELECIONAR MEMBRO" grid_template_column="1fr 1fr 1fr">
                    <SelectBox name="member_name" label="" options={memberOptions} onChange={handleMemberChange} />
                    <SelectBox name="movement_type" label="" options={movementTypeOptions} onChange={handleMovementChange} />
                    <RadioInput name="side" label="" options={membersSides} />
                  </FormSection>

                  <FormSection title="AMPLITUDE DO MOVIMENTO">
                    <Input name="initial_position" label="Posição inicial °" placeholder="0" grid_column="1 / 2" value={initialPos} disable selected={currentInputAngle === 0} />
                    <Input name="end_position" label="Posição final °" placeholder="0" grid_column="2 / 3" value={finalPos} disable selected={currentInputAngle === 1} />
                    <Input name="adm_value" label="ADM °" placeholder="0" grid_column="3 / 4" value={admValue} disable />
                    <Input name="default_pattern" label="Padrão normal" placeholder="0" grid_column="4 / 5" value={degree} disable />
                    <StepInput name="eva_value" label="Escala de EVA" min={0} max={9} rangeValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} grid_column="1 / 3" />
                  </FormSection>

                  { allMovements.map((movement) => (
                    <MovementSection
                      key={movement.memberName}
                      onDelete={() => handleDeleteMovement(movement)}
                      memberName={movement.memberName}
                      sideValue={movement.sideValue}
                      movementType={movement.movementType}
                      initialPosition={movement.initialPosition}
                      finalPosition={movement.finalPosition}
                      admValue={movement.admValue}
                      degree={movement.degree}
                      eva={movement.eva}
                    />
                  ))}
                </Step>
              )}

              { currentStep === 2 && (
                <Step>

                  { allMovements.map((movement) => (
                    <MovementSection
                      onDelete={() => handleDeleteMovement(movement)}
                      key={movement.memberName}
                      memberName={movement.memberName}
                      sideValue={movement.sideValue}
                      movementType={movement.movementType}
                      initialPosition={movement.initialPosition}
                      finalPosition={movement.finalPosition}
                      admValue={movement.admValue}
                      degree={movement.degree}
                      eva={movement.eva}
                    />
                  ))}
                </Step>
              )}
              <FormSection title="" grid_template_column="1fr 1fr 1fr">
                {currentStep === 2 && (
                <Button
                  type="button"
                  grid_column="2 / 3"
                  filledColor={theme.colors.secondary00}
                  outlineColor={theme.colors.primary50}
                  onClick={reload}
                >
                  Reavaliar
                </Button>
                )}
                <Button
                  type="submit"
                  grid_column="3 / 4"
                  icon={currentStep === 2 ? null
                    : <span className="material-icons">navigate_next</span>}
                >
                  {currentStep === 2 ? 'Salvar' : 'Seguinte'}
                </Button>
              </FormSection>
            </Form>
          </Body>
        </FormContent>
      </Content>
    </Container>
  );
};
