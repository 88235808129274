import React from 'react';

import {
  Container,
} from './styles';

interface ClientButtonProps{
  name: string,
  last_rate: string | Date
  created_at: string | Date
  handleClick?: Function;
}

export const ClientButton: React.FC<ClientButtonProps> = ({
  name, last_rate, created_at, handleClick = () => {},
}) => (
  <Container onClick={() => handleClick()}>
    <h1>{name}</h1>
    <h1>{last_rate}</h1>
    <h1>{created_at}</h1>
  </Container>
);
