import https from 'https';
// importação do axios
import axios from 'axios';

// import https from 'https';
// // eslint-disable-next-line @typescript-eslint/no-var-requires
// const fs = require('fs');

const baseURL = 'https://senaisolucoes.com.br:2222';
// const baseURL = 'http://192.168.1.191:2222';

// cria uma conexão
const api = axios.create({
  baseURL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

// const httpsAgent = new https.Agent({
//   key: fs.readFileSync('../resources/cert/client2.key'),
//   cert: fs.readFileSync('../resources/cert/client2.pem'),
// });

// const httpsAgent = new https.Agent({
//   requestCert: true,
//   rejectUnauthorized: false,
// });

// api.defaults.httpsAgent = httpsAgent;

// exporta a conexão
export default api;
