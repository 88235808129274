import styled from 'styled-components';
import { theme } from '../../global/styles/styles';

export const SidebarContainer = styled.div`
  background: ${theme.colors.primary50};
  position: fixed;
  width: 15%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const HeadContent = styled.div`
  height: 300px;
  border-radius: 0 0 20px 20px;
  text-align: center;
`;

export const BodyContent = styled.div`
  flex-grow: 1;
`;

export const FooterContent = styled.div`
  text-align: center;
`;

export const TopBar = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 54px;
  text-align: right;
  top: 0;
  background: ${theme.colors.primary50};

  padding-left: 20%;
`;
