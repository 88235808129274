import React from 'react';
import { FormSection } from '../forms/FomrSection';
import { Input } from '../forms/Input';
import { StepInput } from '../forms/StepInput';

import {
  Container,

} from './styles';

export interface MovementSectionProps{
  memberName: string;
  movementType: string;
  sideValue: string;
  initialPosition: number;
  finalPosition: number;
  admValue: number;
  degree: string;
  eva: number;
  onDelete?: Function;
}

export const MovementSection: React.FC<MovementSectionProps> = ({
  memberName,
  movementType,
  sideValue,
  initialPosition,
  finalPosition,
  admValue,
  degree,
  eva,
  onDelete = () => {},
}) => (
  <Container onClick={() => onDelete()}>
    <FormSection title={`${memberName} - ${sideValue} - ${movementType}`}>
      <Input name="initialPosition" label="Posição inicial" placeholder="0" grid_column="1 / 2" value={initialPosition} disable />
      <Input name="finalPosition" label="Posição final" placeholder="0" grid_column="2 / 3" value={finalPosition} disable />
      <Input name="admValue" label="ADM" placeholder="0" grid_column="3 / 4" value={admValue} disable />
      <Input name="degree" label="Padrão normal" placeholder="0" grid_column="4 / 5" value={degree} disable />
      <StepInput name="eva" label="Escala de EVA" defaultValue={eva} value={eva} min={0} max={9} rangeValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} grid_column="1 / 3" disabled />
    </FormSection>
  </Container>
);
