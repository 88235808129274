import styled from 'styled-components';
import { theme } from '../../../../../global/styles/styles';
import Tooltip from '../../../../Tooltip';

export const Container = styled.div`
  display: flex;
  align-items: center;

  max-height: 50px;


  label {
    font-size: 14px;
    color: ${theme.colors.secondary60};
    margin-right: 10px;

    :hover{
      cursor: pointer;
    }
  }


  input[type="radio"] {
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    width:  20px;
    height:  20px;

    background-color: transparent;
    border: 2px solid ${theme.colors.secondary60};
    border-radius: 10px;
    display: inline-block;
    margin-right: 15px;
    position: relative;
    -webkit-appearance: none;

}

  input[type="radio"]:after {
    background-color: transparent;
    border-radius: 25px;
    content: '';
    display: block;
    height: 10px;
    left: 3px;
    position: relative;
    top: 3px;
    width: 10px;
}

  input[type="radio"]:checked:after {
    background-color: ${theme.colors.secondary80};

}
`;

export const Error = styled(Tooltip)`
  align-self: center;
  height: 20px;
  margin: 0 8px;


  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
