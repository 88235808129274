import React, { useEffect } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  setPathname: (pathname: string) => void;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false, component: Component, setPathname, ...rest
}) => {
  const { user } = useAuth();

  const locationRouter = useLocation();

  useEffect(() => {
    setPathname(locationRouter.pathname);
  }, [locationRouter.pathname, setPathname]);

  return (
  // <ReactDOMRoute
  //   {...rest}
  //   render={({ location }) => (<Component />)}
  // />
    <ReactDOMRoute
      {...rest}
      render={({ location }) => (isPrivate === !!user ? (
        <Component />
      ) : (
        <Redirect to={{
          pathname: isPrivate ? '/' : '/pacientes',
          state: { from: location },
        }}
        />
      ))}
    />
  );
};

export default Route;
