export function getAngle(
  originX: number,
  originY: number,
  targetX: number,
  targetY: number,
  zeroSide: boolean,
  side: string,
  is_clockwise: boolean,
): number {
  const dx = originX - targetX;
  const dy = originY - targetY;

  let theta = 0;

  if (!zeroSide) {
    if (is_clockwise) {
      // console.log('Direito', 'Horário');
      theta = Math.atan2(dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = east
      theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; anticlockwise; 0° = east
      if (theta < 0) theta += 360; // [0, 360]; anticlockwise; 0° = east
    } else {
      // console.log('Direito', 'Anti-Horário');
      theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
      theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = east
      if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = east
    }
  } else if (is_clockwise) {
    // console.log('Esquerdo', 'Horário');
    theta = Math.atan2(-dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = west
    theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; anticlockwise; 0° = west
    if (theta < 0) theta += 360; // [0, 360]; anticlockwise; 0° = west
  } else {
    // console.log('Esquerdo', 'Anti-Horário');
    theta = Math.atan2(dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = west
    theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = west
    if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = west
  }

  // theta = Math.atan2(dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = west
  // theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = west
  // if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = west

  // var theta = Math.atan2(-dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = west
  // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; anticlockwise; 0° = west
  // if (theta < 0) theta += 360;     // [0, 360]; anticlockwise; 0° = west

  // theta = Math.atan2(dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = east
  // theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; anticlockwise; 0° = east
  // if (theta < 0) theta += 360; // [0, 360]; anticlockwise; 0° = east

  // theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
  // theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = east
  // if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = east

  return theta;
}
