/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import pt from 'date-fns/locale/pt-BR';
import { useField } from '@unform/core';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Container, Error,
} from './styles';

import 'react-datepicker/dist/react-datepicker.css';

interface InputProps extends Omit<ReactDatePickerProps, 'onChange'>{
  name: string;
  label: string;
  placeholder: string;
  newValue?: any;
  grid_column?: string
}

registerLocale('pt-BR', pt);

export const DateInput: React.FC<InputProps> = ({
  name, label, placeholder, newValue = null, grid_column, ...rest
}) => {
  const datePickerRef = useRef(null);

  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    setDate(newValue);
  }, [newValue]);

  const handleDateChangeRaw = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      grid_column={grid_column}
    >
      <h1>{label}</h1>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DatePicker
          locale="pt-BR"
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          dropdownMode="select"
          scrollableYearDropdown
          placeholderText={placeholder}
          ref={datePickerRef}
          selected={date as any}
          onChange={setDate}
          onChangeRaw={handleDateChangeRaw}
          {...rest}
        />
        {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
        )}
      </div>
    </Container>
  );
};
