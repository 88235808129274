import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Link, useHistory } from 'react-router-dom';

import Logo from '../../assets/logo.png';

import {
  Container,
  Content,
  TextArea,
  Background,
  AnimationContainer,
} from './styles';
import getValidationErros from '../../utils/getValidationErrors';
import { Button } from '../../components/forms/Button';
import { Input } from '../../components/forms/Input';
import { theme } from '../../global/styles/styles';
import { useAuth } from '../../hooks/auth';

// define o tipo dos dados do formulario
interface SingInFormData{
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { signIn } = useAuth();

  // função para submeter os dados do formulario
  const handleSignIn = useCallback(async (data: SingInFormData) => {
    setLoading(true);
    try {
      // aplica os erros ao component se houver
      formRef.current?.setErrors({});

      // cria o esquema de validação de dados
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().required('Senha obrigatória'),
      });

      // verifica a validação do esquema criado
      await schema.validate(data, {
        abortEarly: false,
      });

      // chama o metod de signin
      await signIn({
        email: data.email,
        password: data.password,
      });

      history.push('/avaliar');
    } catch (err) {
      // se o erro for uma instancia de validação
      if (err instanceof Yup.ValidationError) {
        // se houver erros na validação aplica os mesmo na tela
        const erros = getValidationErros(err);

        // salva os erros no formulario
        formRef.current?.setErrors(erros);

        return;
      }
    } finally {
      setLoading(false);
    }
  }, [history, signIn]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSignIn}>
            <TextArea>
              <img src={Logo} alt="Logo Goniotech" />
              <h2>Bem-vindo!</h2>
            </TextArea>

            <Input name="email" label="E-mail" placeholder="Digite aqui seu e-mail" label_color={theme.colors.primary50} />

            <Input name="password" label="Senha" type="password" placeholder="Senha" label_color={theme.colors.primary50} />

            <Link to="/forgot">Esqueci minha senha</Link>

            <Button type="submit" loading={loading}>
              Login
            </Button>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};
