import styled from 'styled-components';
import { theme } from '../../../global/styles/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const FormContent = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;

  min-height: 38px;
  max-height: 38px;
  border: 1px solid ${theme.colors.secondary20};
  box-shadow: 0px 2px 4px rgba(213, 213, 213, 0.25);

  border-radius: 8px;
  margin-bottom: 32px;

  button {
    color: #345687;
    background: transparent;
    border: 0;
    margin-right: 18px;
  }
`;

export const Title = styled.h1`
  color: ${theme.colors.primary50};
  align-self: center;
  text-align: center;

  width: 100%;

  font-weight: bold;
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  margin: 0 6px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
  }
`;
