import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/sign-in-background.png';
import { theme } from '../../global/styles/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;

  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display:flex;
  min-width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 18px;
  }
`;

export const Background = styled.div`
  flex: 1;
  display: flex;
  height: 80%;
  margin-right: 5%;

  background: url(${signInBackgroundImg}) no-repeat center;

  background-size: cover;
`;

export const TextArea = styled.div`
  width: 100%;

  margin-bottom: 74px;
  margin-top:65px;
  text-align: center;

  img {
    width: 200px
  }

  h2 {
    font-size: 18px;
    line-height: 22px;
    color: ${theme.colors.secondary80};
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;


  width: 579px;
  height: 613px;

  border-radius: 24px;

  background: ${theme.colors.secondary00};

  animation: ${appearFromRight} 1s;

  form {
    width: 100%;
    padding: 0 93px 131px 93px;
    text-align: left;

    a {
      color: ${theme.colors.primary50};
      text-decoration: underline;
      display: block;
      margin: -8px 0 46px;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, theme.colors.secondary00)}
      }
    }
  }
`;
