import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;


  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;

  border-radius: 50%;

  background-color: white;
  margin-bottom: 16px;
`;

export const TextArea = styled.div`
  display: flex;

  flex-direction: column;

  h1 {
    font-size: 12px;
    line-height: 15px;

    margin-bottom: 8px;

    color: #FFF;
  }
`;
