import React from 'react';

import {
  Container,
  Title,
  Content,
} from './styles';

interface FormSectionProps {
  title: string;
  grid_template_column?: string;
}

export const FormSection: React.FC<FormSectionProps> = ({ title, grid_template_column = '1fr 1fr 1fr 1fr', children }) => (
  <Container>
    <Title>
      <h1>
        {title}
      </h1>
    </Title>
    <Content grid_template_column={grid_template_column}>
      {children}
    </Content>
  </Container>
);
