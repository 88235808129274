import styled from 'styled-components';
import { theme } from '../../../global/styles/styles';

export const Container = styled.div`
  width: 100%;
  flex-direction: column;

  min-height: 64px;

  text-align: left;


  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${theme.colors.secondary100};
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-height: max-content;
  padding: 0 18px;
  margin-bottom: 4px;
`;
