import styled from 'styled-components';
import { theme } from '../../global/styles/styles';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  position: absolute;

  z-index: 2000;

  justify-content: center;
  align-items: center;

  background-color: rgba(0 , 0, 0, .5);
`;

export const Content = styled.div`
  width: 470px;
  height: 350px;

  border-radius: 18px;

  background-color: ${theme.colors.secondary00};

  padding: 28px;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 35%;

  > img {
    margin-top: 35px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 1;
  height: 15%;
  width: 100%;

  justify-content: start;
  align-items: center;


  button {
    color: #345687;
    background: transparent;
    border: 0;
    margin-right: 18px;
  }

  h1 {
    width: 100%;

    font-size: 22px;
    text-align: center;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  height: 50%;

  overflow-y: scroll;

  h1 {
    font-size: 18px;
    line-height: 24px;
    color: ${theme.colors.secondary60};
    text-align: center;

    width: 70%;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  height: 15%;

  grid-gap: 18px;
`;
