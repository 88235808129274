// importação do react
import React from 'react';

// importação do estilo de container
import { Container } from './styles';

// define o tipo do componente de tooltip
interface TooltipProps {
  title: string;
  className?: string;
}

// cria o component de tooltip
const Tooltip: React.FC<TooltipProps> = ({ title, className, children }) => (
  <Container className={className}>
    {children}
    <span>{title}</span>
  </Container>
);

// exporta o component
export default Tooltip;
