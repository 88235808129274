import styled from 'styled-components';
import { theme } from '../../../global/styles/styles';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 80px 10% 80px 20%;

`;

export const ButtonArea = styled.div`
  display: flex;
  flex: 1;
  height: 64px;
  padding-top: 4px;
  grid-column: 3 / 3;

  button {
    height: 36px;
  }
`;

export const SearchArea = styled.div`
  align-self: center;

  grid-column: 1 / 4;
  grid-row: 1 / 1;

  form {
    display: grid;
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;

    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ListContent = styled.div`
  background-color: ${theme.colors.secondary00};

  display:flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  grid-column: 1 / 3;
  grid-row: 2 / 2;
`;

export const TableHeader = styled.div`
  height: 38px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column: 1 / 5;
  grid-row: 2 / 2;
  padding: 0 18px ;

  border: 1px solid #E5E5E5;
  box-shadow: 0px 2px 4px rgb(213 213 213 / 25%);
  border-radius: 8px;

  h1 {
    color: ${theme.colors.primary50};
    align-self: center;
    text-align: left;


    width: 100%;

    font-weight: bold;
    font-size: 14px;
  }
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;

  grid-column: 1 / 3;
  grid-row: 2 / 2;

  overflow: scroll;
`;
