import React from 'react';

import {
  Container,
} from './styles';

interface ClientButtonProps{
  name: string,
  cpf: string,
  birth_date: string | Date
  created_at: string | Date
  handleClick?: Function;
}

export const ClientButton: React.FC<ClientButtonProps> = ({
  name, cpf, birth_date, created_at, handleClick = () => {},
}) => (
  <Container onClick={() => handleClick()}>
    <h1>{name}</h1>
    <h1>{cpf}</h1>
    <h1>{birth_date}</h1>
    <h1>{created_at}</h1>
  </Container>
);
