import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import { Input } from '../../components/forms/Input';
import { ClientButton } from './components/ClientButton';

import {
  Container,
  ListContent,
  TableHeader,
  BodyContent,
  SearchArea,
} from './styles';
import api from '../../services/api';

export interface ReportProps {
  patient_id: string;
  patient_name: string;
  last_avaliation: Date | string;
  created_at: Date | string;
}

export const Reports: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [listPatients, setListPatients] = useState<ReportProps[]>([]);
  const [filteredPatients, setFilteredPatients] = useState<ReportProps[]>([]);

  const configPatients = useCallback((list: ReportProps[]) => {
    const temp = list.map((client) => {
      const c = {
        ...client,
        created_at: new Date(client.created_at).toLocaleDateString(),
        last_avaliation: new Date(client.last_avaliation).toLocaleDateString(),
      };

      return c;
    });

    setListPatients(temp);
    setFilteredPatients(temp);
  }, []);

  const handleSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const filtered = listPatients.filter(
      (client) => client.patient_name.toLowerCase()
        .includes(event.currentTarget.value.toLowerCase()),
    );
    setFilteredPatients(filtered);
  }, [listPatients]);

  const handleClick = useCallback(async (report: ReportProps) => {
    api.get(`/patient/report/${report.patient_id}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }, []);

  useEffect(() => {
    api.get('/patient/report').then((response) => {
      const list: ReportProps[] = response.data;

      configPatients(list);
    });
  }, [configPatients]);

  return (
    <Container>
      <SearchArea>
        <Form ref={formRef} onSubmit={handleSearch}>
          <Input
            name="search"
            onChange={handleSearch}
            label=""
            placeholder="Pesquisar"
          />
        </Form>
      </SearchArea>
      <ListContent>
        <TableHeader>
          <h1>Nome</h1>
          <h1>Última Avaliação</h1>
          <h1>Cadastrado em</h1>
        </TableHeader>
        <BodyContent>
          {filteredPatients.map((item) => (
            <ClientButton
              key={item.patient_name}
              name={item.patient_name}
              created_at={item.created_at}
              last_rate={item.last_avaliation}
              handleClick={() => handleClick(item)}
            />
          ))}
        </BodyContent>
      </ListContent>
    </Container>
  );
};
