import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
import { isDate } from 'moment';
import { Button } from '../../../components/forms/Button';
import { DateInput } from '../../../components/forms/DateInput';
import { FormSection } from '../../../components/forms/FomrSection';
import { Input } from '../../../components/forms/Input';
import { PatientProps } from '../ListPatients';
import getValidationErros from '../../../utils/getValidationErrors';

import {
  Container,
  FormContent,
  Header,
  Title,
  Body,
} from './styles';
import { useModal } from '../../../hooks/Modal';
import { removeMask } from '../../../utils/masks';
import api from '../../../services/api';

interface PatientPanelProps {
  onClose?: Function;
  patient: PatientProps;
}

export const PatientPanel: React.FC<PatientPanelProps> = ({
  onClose = () => {},
  patient,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { configModal, handleVisible } = useModal();

  const handleSavePatient = useCallback(async (data: PatientProps) => {
    try {
      // aplica os erros ao component se houver
      formRef.current?.setErrors({});

      // cria o esquema de validação de dados
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Nome obrigatório'),
        cpf: Yup.string().test(
          'is-cpf',
          'Digite um CPF válido',
          (value) => validateCPF(value as string),
        )
          .required('CPF obrigatório'),
        birth_date: Yup.string().test(
          'is-Date',
          'Digite uma Data válida',
          (value) => isDate(new Date(value as string)),
        ).required('Data de início obrigatória'),
      });

      // verifica a validação do esquema criado
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/patient', {
        name: data.name,
        cpf: removeMask(data.cpf as string),
        birth_date: new Date(data.birth_date as string).toLocaleDateString(),
      }).catch((err) => {
        configModal('Erro na requisição', err.response.data.message);
        handleVisible();
      }).then((response) => {
        if (response?.status && response.status >= 200 && response.status <= 299) {
          onClose();
        }
      });
    } catch (err) {
      // se o erro for uma instancia de validação
      if (err instanceof Yup.ValidationError) {
        // se houver erros na validação aplica os mesmo na tela
        const erros = getValidationErros(err);

        // salva os erros no formulario
        formRef.current?.setErrors(erros);
      }
    }
  }, [configModal, handleVisible, onClose]);

  return (
    <Container>
      <FormContent>
        <Header>
          <Title>{patient ? 'Adicionar Paciente' : 'Editar Paciente'}</Title>
          <button type="button" onClick={() => onClose()}>
            <RiCloseLine size={24} />
          </button>
        </Header>
        <Body>
          <Form
            ref={formRef}
            initialData={{ ...patient }}
            onSubmit={handleSavePatient}
          >

            <FormSection title="DADOS PACIENTE">
              <Input name="name" label="Nome" placeholder="Digite o nome do paciente" grid_column="1 / 3" />
              <Input name="cpf" label="CPF" placeholder="Digite o cpf" grid_column="1 / 2" mask="cpf" />
              <DateInput name="birth_date" label="Data de nascimento" placeholder="DD/MM/AAAA" grid_column="2 / 3" newValue={patient.birth_date} />

              <Button
                type="submit"
                grid_column="2 / 3"
              >
                Salvar
              </Button>
            </FormSection>

          </Form>
        </Body>
      </FormContent>
    </Container>

  );
};
