export const theme = {
  colors: {
    primary100: '#375C7E',
    primary50: '#4D7DA9',
    primary10: '#84C1CA',

    secondary100: '#333333',
    secondary80: '#616262',
    secondary60: '#B7BBBC',
    secondary40: '#C7C7C7',
    secondary20: '#E5E5E5',
    secondary00: '#FFF',

    error: '#E15039',
  },
  fonts: {
    titleText: 'Montserrat',
  },
};
