import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { theme } from '../../../global/styles/styles';

interface ButtonProps {
  borderStyles: string;
  filledColor: string;
  outlineColor: string;
  hasShadow: boolean;
  height: number;
  iconColor: string;
  grid_column?: string;
  alignItems: 'center' | 'start';
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;

  ${(props) => props.alignItems && css`
    justify-content: ${props.alignItems};
  `}

  border-radius: 4px;

  border: 0;

  background: ${theme.colors.primary100};
  color: ${theme.colors.secondary00};

  font-size: 14px;
  text-transform: uppercase;

  height: 44px;
  transition: background-color 0.2s;
  border: 0;

  &:hover {
    background: ${shade(0.2, theme.colors.primary50)}
  }

  span {
    margin-left: 18px;

    ${(props) => props.iconColor && css`
      color: ${props.iconColor};
    `}
  }

  ${(props) => props.height > 44 && css`
    max-height: ${props.height}px;
  `}

  ${(props) => props.filledColor && css`
    background: ${props.filledColor};
    color: ${props.color ? props.color : theme.colors.primary50};

    &:hover {
      background: ${shade(0.2, props.filledColor)}
    }
  `}

  ${(props) => props.color && css`
    color: ${props.color};
  `}


  ${(props) => props.outlineColor && css`
    border: 1px solid ${props.outlineColor};
  `}

  ${(props) => props.hasShadow && css`
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 6px 8px -1px rgba(181, 200, 227, 0.5);
  `}

  ${(props) => props.borderStyles && css`
    border-style: ${props.borderStyles};
  `}

  ${(props) => props.grid_column && css`
    grid-column: ${props.grid_column};
  `}
`;
