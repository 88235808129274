import styled from 'styled-components';
import { theme } from '../../global/styles/styles';
import Silhouette from '../../assets/silhouette.png';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 80px 10% 80px 20%;

`;

export const CameraContent = styled.div`
  border: 1px solid ${theme.colors.secondary20};
  border-radius: 8px;
  height: 720px;
  width: 480px;

  background: url(${Silhouette}) no-repeat center;
  background-size: 50%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  flex-direction: column;
  justify-content:space-between;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;

  gap: 32px;

  overflow: hidden;
`;

export const FormContent = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;

  min-height: 38px;
  max-height: 38px;
  border: 1px solid ${theme.colors.secondary20};
  box-shadow: 0px 2px 4px rgba(213, 213, 213, 0.25);

  border-radius: 8px;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  color: ${theme.colors.primary50};
  align-self: center;
  text-align: center;

  width: 100%;

  font-weight: bold;
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  margin: 0 6px;

  overflow-x: hidden;
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Step = styled.div``;
