import styled, { css } from 'styled-components';
import { theme } from '../../../../global/styles/styles';

interface ContainerPros {
  isSelected: boolean;
}

export const Container = styled.div<ContainerPros>`
  height: 70px;

  div {
    align-items: center;
    display: inline-flex;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: ${theme.colors.secondary00};
    border-radius: 0;
    background: ${theme.colors.primary50};

    ${(props) => props.isSelected && css`
      color: ${theme.colors.primary50};
      background: ${theme.colors.secondary00};
    `}

    &:hover {
      cursor: pointer;
      ${(props) => !props.isSelected && css`
        background: rgba(255, 255, 255, 0.1);
    `}
    }

    svg {
      margin-right: 12px;
      margin-left: 57px;
    }

    h1 {
      display: inline-block;
      font-size: 18px;

      text-transform: uppercase;
    }
  }
`;
