import { useField } from '@unform/core';
import React, {
  useRef, useEffect,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Props as SelectProps,
} from 'react-select';

import {
  Container,
  SelectContainer,
  Error,
} from './styles';

export interface SelectOptionsProps {
  value: string;
  label: string;
  disable?: boolean;
}

export const Select: React.FC<SelectProps> = ({ name, options, ...rest }) => {
  const selectRef = useRef(null);
  // define o hook de do campo de formulario
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: any) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container className="react-select-container" prefix="react-select">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SelectContainer
          isSearchable={false}
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          {...rest}
          options={options}
        />
        {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
        )}
      </div>
    </Container>
  );
};
