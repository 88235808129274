import React, {
  InputHTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';

import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import {
  Container, Error,
} from './styles';
import {
  cellPhone,
  cep,
  cpf,
  cpfOrCnpj,
  currencyMask,
  date,
  metersMask,
  numberMask,
  tel,
  time,
  alphaNumericMask,
} from './masks';

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
  name: string;
  containerStyle?: object;
  label: string;
  onHandleChange?: Function;
  icon?: ReactNode;
  disable?: boolean;
  selected?: boolean;
  grid_column?: string;
  label_color?: string;
  mask?:'alpha-numeric' | 'cep' | 'tel' | 'cell-phone' | 'cpf' | 'date' | 'numeric' | 'time' | 'currency' | 'cpfOrCnpj' | 'meters' | undefined
}

export const Input: React.FC<InputProps> = ({
  name,
  disable = false,
  containerStyle = {},
  selected = false,
  icon: Icon, label, mask, grid_column, onHandleChange = () => {}, label_color, ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // define os hooks de estados de focus e fill
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  // define o hook de do campo de formulario
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  // cria a função de focus usando o hook de callback
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  // cria a função de blur do input usando o hook de callback
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    switch (mask) {
      case 'cep':
        cep(e);
        break;
      case 'tel':
        tel(e);
        break;
      case 'cell-phone':
        cellPhone(e);
        break;
      case 'cpf':
        cpf(e);
        break;
      case 'date':
        date(e);
        break;
      case 'time':
        time(e);
        break;
      case 'numeric':
        numberMask(e);
        break;
      case 'currency':
        currencyMask(e);
        break;
      case 'cpfOrCnpj':
        cpfOrCnpj(e);
        break;
      case 'meters':
        metersMask(e);
        break;
      case 'alpha-numeric':
        alphaNumericMask(e);
        break;
      default:
        break;
    }
    onHandleChange();
  }, [mask, onHandleChange]);

  // chama o hooks de efeito para registrar o campo do formulario
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      label_color={label_color}
      grid_column={grid_column}
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      selected={selected}
      disable={disable}
    >
      {Icon && { Icon } }
      <h1>{label}</h1>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <input
          disabled={disable}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          onChange={handKeyUp}
          {...rest}
        />
        {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
        )}
      </div>
    </Container>
  );
};
